import axios from 'axios';

// const API_BASE_URL = 'https://localhost:7131'; 
const API_BASE_URL = 'https://interest-generator-api.azurewebsites.net';
const TOKEN_KEY = 'jwt_token';

// Setup Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to inject the JWT token into all requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors globally
axiosInstance.interceptors.response.use(
  (response) => response, // If the response is successful, return it
  (error) => {
    if (error.response && error.response.status === 401) {
      // If the server responds with a 401, redirect to the login page
      localStorage.removeItem(TOKEN_KEY); // Clear the token
      window.location.href = '/login'; // Redirect to the login page
    }
    return Promise.reject(error);
  }
);

// Authentication functions
export const login = async (username, password) => {
  try {
    const response = await axiosInstance.post('/Authorize/GenerateToken', { username, password });
    const { token } = response.data;  // Assuming the response contains a token field
    localStorage.setItem(TOKEN_KEY, token);
    return token;
  } catch (error) {
    throw new Error('Login failed');
  }
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isAuthenticated = () => {
  return !!getToken();
};

// Export axios instance for other services to use
export default axiosInstance;
