import axiosInstance from './authService';

export const getLicenses = async () => {
  try {
    const response = await axiosInstance.get('/api/license');
    return response.data;
  } catch (error) {
    console.error('Error fetching licenses:', error);
    throw error;
  }
};

export const createLicense = async (licenseData) => {
  try {
    const response = await axiosInstance.post('/api/license', licenseData);
    return response.data;
  } catch (error) {
    console.error('Error creating license:', error);
    throw error;
  }
};

export const updateLicense = async (licenseId, licenseData) => {
  try {
    const response = await axiosInstance.put(`/api/license/${licenseId}`, licenseData);
    return response.data;
  } catch (error) {
    console.error('Error updating license:', error);
    throw error;
  }
};

export const deleteLicense = async (licenseId) => {
  try {
    await axiosInstance.delete(`/api/license/${licenseId}`);
  } catch (error) {
    console.error('Error deleting license:', error);
    throw error;
  }
};

export const getLicense = async (licenseId) => {
  try {
    const response = await axiosInstance.get(`/api/license/${licenseId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching license:', error);
    throw error;
  }
};

export const generateNewKey = async(licenseId) => {
  try {
    const response = await axiosInstance.post(`/api/License/GenerateNewLicenseKey?id=${licenseId}`);
    return response.data;
  } catch (error) {
    console.error('Error generating new license key:', error);
  }
};
