import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LicenseListPage from './pages/LicenseListPage';
import LicenseFormPage from './pages/LicenseFormPage';
import Login from './components/Login';
import DashboardPage from './pages/DashboardPage';
import EconomicCalendarPage from './pages/EconomicCalendarPage';
import EconomicCalendarDetailPage from './pages/EconomicCalendarDetailPage';
import RiskSettingsPage from './pages/RiskSettingsPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path='/' element={<DashboardPage />} />
        <Route path="/licenses" element={<LicenseListPage />} />
        <Route path="/licenses/create" element={<LicenseFormPage />} />
        <Route path="/licenses/edit/:id" element={<LicenseFormPage />} />
        <Route path='/calendar' element={<EconomicCalendarPage />} />
        <Route path="/calendar/detail/:date" element={<EconomicCalendarDetailPage />} />
        <Route path="/risksettings" element={<RiskSettingsPage />} />
        <Route path="/risksettings/edit" element={<RiskSettingsPage />} />
      </Routes>
    </Router>
  );
};

export default App