import React, { useEffect, useState } from 'react';
import { getRiskSettings } from '../services/riskSettingsService';
import { Paper, Box, Button, Typography, Chip, Grid, IconButton, Stack } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import ScheduleCalendar from '../components/ScheduleCalendar';
import { useNavigate } from 'react-router-dom';

const RiskSettings = () => {
  const [riskSettings, setRiskSettings] = useState(null);
  const navigate = useNavigate();

  const fetchRiskSettings = async () => {
    try {
      const data = await getRiskSettings();
      setRiskSettings(data);
    } catch (error) {
      console.error('Error fetching risk settings:', error);
    }
  };

  useEffect(() => {  
    fetchRiskSettings();
  }, []);

  if (!riskSettings) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5">Loading Risk Settings...</Typography>
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Paper sx={{ p: 4 }}>
        {/* Top Panel with Edit and Refresh Buttons */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h5">Risk Settings</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => navigate('/risksettings/edit')}
            >
              Edit Risk Settings
            </Button>
            <IconButton onClick={() => fetchRiskSettings()}>
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Box>

        {/* Main Content */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Impacts:</Typography>
            {riskSettings.impacts.map((impact, index) => (
              <Chip key={index} label={impact} color="warning" size="small" sx={{ marginRight: 1, marginBottom: 1 }} />
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Currencies:</Typography>
            {riskSettings.currencies.map((currency, index) => (
              <Chip key={index} label={currency} color="primary" size="small" sx={{ marginRight: 1, marginBottom: 1 }} />
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Hours Into Next Event:</strong> {riskSettings.hoursIntoNextEvent}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Hours From Last Event:</strong> {riskSettings.hoursFromLastEvent}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Max VIX Score:</strong> {riskSettings.maxVixScore}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Enable Trading:</strong> {riskSettings.enableTrading ? 'Yes' : 'No'}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Disable Start Time:</strong> {new Date(riskSettings.disableStartTimeUTC).toLocaleString()}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Disable End Time:</strong> {new Date(riskSettings.disableEndTimeUTC).toLocaleString()}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1"><strong>Re-Occuring:</strong> {riskSettings.isRepeat ? 'Yes' : 'No'}</Typography>
          </Grid>
        </Grid>

        {/* Risk Schedules Section */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>Risk Schedules</Typography>
          <ScheduleCalendar riskSchedules={riskSettings.riskSchedules} readOnly={true} />
        </Box>
      </Paper>
    </Box>
  );
};

export default RiskSettings;
