import React, { useEffect, useState } from 'react';
import { getRiskSettings, updateRiskSettings } from '../services/riskSettingsService';
import { Paper, Box, Typography, Button, TextField, Switch, FormControlLabel, Select, MenuItem, Chip, Grid, Stack } from '@mui/material';
import ScheduleCalendar from './ScheduleCalendar';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { predefinedImpacts, predefinedCurrencies } from '../helpers/constants';

const RiskSettingsEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [editableSettings, setEditableSettings] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRiskSettings = async () => {
      try {
        const data = await getRiskSettings();
        // Convert UTC to local time for display purposes
        setEditableSettings({
          ...data,
          riskSchedules: data.riskSchedules.map(schedule => ({
            ...schedule,
            disableStartDateTimeUTC: schedule.disableStartDateTimeUTC ? new Date(schedule.disableStartDateTimeUTC).toLocaleString('sv-SE').replace(' ', 'T') : '',
            disableEndDateTimeUTC: schedule.disableEndDateTimeUTC ? new Date(schedule.disableEndDateTimeUTC).toLocaleString('sv-SE').replace(' ', 'T') : '',
          })) || [],
          disableStartTimeUTC: data.disableStartTimeUTC ? new Date(data.disableStartTimeUTC).toLocaleString('sv-SE').replace(' ', 'T') : '',
          disableEndTimeUTC: data.disableEndTimeUTC ? new Date(data.disableEndTimeUTC).toLocaleString('sv-SE').replace(' ', 'T') : '',
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching risk settings:', error);
        setLoading(false);
      }
    };

    fetchRiskSettings();
  }, []);

  const handleChange = (field, value) => {
    setEditableSettings(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleDeleteImpact = (impactToDelete) => {
    setEditableSettings(prev => ({
      ...prev,
      impacts: prev.impacts.filter((impact) => impact !== impactToDelete),
    }));
  };

  const handleDeleteCurrency = (currencyToDelete) => {
    setEditableSettings(prev => ({
      ...prev,
      currencies: prev.currencies.filter((currency) => currency !== currencyToDelete),
    }));
  };

  const handleSave = async () => {
    try {
      const updatedSchedulesWithUTC = editableSettings.riskSchedules.map(schedule => {
        // Convert the local datetime inputs to UTC before saving to backend
        const localStart = new Date(schedule.disableStartDateTimeUTC);
        const localEnd = new Date(schedule.disableEndDateTimeUTC);

        return {
          ...schedule,
          disableStartDateTimeUTC: schedule.disableStartDateTimeUTC ? localStart.toISOString() : null,
          disableEndDateTimeUTC: schedule.disableEndDateTimeUTC ? localEnd.toISOString() : null,
        };
      });

      const updatedSettings = {
        ...editableSettings,
        riskSchedules: updatedSchedulesWithUTC,
        // Convert other local date-time inputs to UTC before saving
        disableStartTimeUTC: editableSettings.disableStartTimeUTC ? new Date(editableSettings.disableStartTimeUTC).toISOString() : null,
        disableEndTimeUTC: editableSettings.disableEndTimeUTC ? new Date(editableSettings.disableEndTimeUTC).toISOString() : null,
      };

      await updateRiskSettings(updatedSettings);
      enqueueSnackbar('Risk settings updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating risk settings', { variant: 'error' });
    }
  };

  const handleScheduleSave = (updatedSchedules) => {
    setEditableSettings(prev => ({
      ...prev,
      riskSchedules: updatedSchedules.map(schedule => ({
        ...schedule,
        disableStartDateTimeUTC: schedule.disableStartDateTimeUTC ? new Date(schedule.disableStartDateTimeUTC).toLocaleString('sv-SE').replace(' ', 'T') : '',
        disableEndDateTimeUTC: schedule.disableEndDateTimeUTC ? new Date(schedule.disableEndDateTimeUTC).toLocaleString('sv-SE').replace(' ', 'T') : '',
      })),
    }));
  };

  const handleClose = () => {
    navigate('/risksettings');
  };

  if (loading || !editableSettings) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h5">Loading Risk Settings...</Typography>
      </Box>
    );
  }

  return (
    <Box padding={4}>
      <Paper sx={{ p: 4 }}>
        {/* Top Panel with Save and Close Buttons */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h5">Editable Risk Settings</Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color="primary" onClick={handleSave} disabled={!editableSettings.enableTrading}>
              Save Changes
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </Box>

        {/* Main Content */}
        <Grid container spacing={4}>
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={editableSettings.enableTrading}
                  onChange={(e) => handleChange('enableTrading', e.target.checked)}
                  color="primary"
                />
              }
              label="Enable Trading"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={editableSettings.isRepeat}
                  onChange={(e) => handleChange('isRepeat', e.target.checked)}
                  color="primary"
                />
              }
              label="Re-Occurring"
            />
            <TextField
              label="Disable Start Time (Local)"
              type="datetime-local"
              value={editableSettings.disableStartTimeUTC}
              onChange={(e) => handleChange('disableStartTimeUTC', e.target.value)}
              fullWidth
              margin="normal"
              disabled={!editableSettings.enableTrading}
            />
            <TextField
              label="Disable End Time (Local)"
              type="datetime-local"
              value={editableSettings.disableEndTimeUTC}
              onChange={(e) => handleChange('disableEndTimeUTC', e.target.value)}
              fullWidth
              margin="normal"
              disabled={!editableSettings.enableTrading}
            />
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <TextField
              label="Max VIX Score"
              value={editableSettings.maxVixScore}
              onChange={(e) => handleChange('maxVixScore', e.target.value)}
              fullWidth
              margin="normal"
              disabled={!editableSettings.enableTrading}
            />
            <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>Impacts:</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem', mt: 1 }}>
              {editableSettings.impacts.map((impact) => (
                <Chip
                  key={impact}
                  label={impact}
                  color="warning"
                  size="small"
                  onDelete={!editableSettings.enableTrading ? null : () => handleDeleteImpact(impact)}
                />
              ))}
            </Box>
            <Select
              multiple
              value={editableSettings.impacts}
              onChange={(e) => handleChange('impacts', e.target.value)}
              fullWidth
              sx={{ mt: 1 }}
              disabled={!editableSettings.enableTrading}
            >
              {predefinedImpacts.map((impact) => (
                <MenuItem key={impact} value={impact}>{impact}</MenuItem>
              ))}
            </Select>

            <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>Currencies:</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem', mt: 1 }}>
              {editableSettings.currencies.map((currency) => (
                <Chip
                  key={currency}
                  label={currency}
                  color="primary"
                  size="small"
                  onDelete={!editableSettings.enableTrading ? null : () => handleDeleteCurrency(currency)}
                />
              ))}
            </Box>
            <Select
              multiple
              value={editableSettings.currencies}
              onChange={(e) => handleChange('currencies', e.target.value)}
              fullWidth
              sx={{ mt: 1 }}
              disabled={!editableSettings.enableTrading}
            >
              {predefinedCurrencies.map((currency) => (
                <MenuItem key={currency} value={currency}>{currency}</MenuItem>
              ))}
            </Select>

            <TextField
              label="Hours From Last Event"
              value={editableSettings.hoursFromLastEvent}
              onChange={(e) => handleChange('hoursFromLastEvent', e.target.value)}
              fullWidth
              margin="normal"
              disabled={!editableSettings.enableTrading}
            />

            <TextField
              label="Hours Into Next Event"
              value={editableSettings.hoursIntoNextEvent}
              onChange={(e) => handleChange('hoursIntoNextEvent', e.target.value)}
              fullWidth
              margin="normal"
              disabled={!editableSettings.enableTrading}
            />
          </Grid>
        </Grid>

        {/* Risk Schedules Section */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>Risk Schedules</Typography>
          <ScheduleCalendar
            riskSchedules={editableSettings.riskSchedules || []}
            onSave={handleScheduleSave}
            readOnly={!editableSettings.enableTrading}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default RiskSettingsEdit;
