import axiosInstance from './authService';

export const getEconomicCalendar = async (timeNavigation = 'this-month') => {
  try {
    const response = await axiosInstance.get(`/calendar/${timeNavigation}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching economic calendar:', error);
    throw error;
  }
};

export const getEconomicCalendarByDate = async (date, timeNavigation = 'this-month') => { 
  try {
    const response = await axiosInstance.get(`/calendar/${timeNavigation}`);
    // Filter events by the provided date
    return response.data.filter(event => event.date === date);
  } catch (error) {
    console.error('Error fetching economic calendar for the specified date:', error);
    throw error;
  }
};
