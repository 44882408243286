import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { createLicense, updateLicense, getLicense, generateNewKey as generateNewKeyFromService } from '../services/licenseService';
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';

const LicenseForm = ({ licenseId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [licenseData, setLicenseData] = useState({
    description: '',
    key: '',
    associatedAccount: '',
    licenseToName: '',
    licenseToEmail: '',
    equitySize: 0,
    validateEquitySize: false,
    expiresOn: '',
    active: true,
  });

  useEffect(() => {
    if (licenseId) {
      const fetchLicense = async () => {
        const data = await getLicense(licenseId);
        setLicenseData(data);
      };
      fetchLicense();
    }
  }, [licenseId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLicenseData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (licenseId) {
      try {
        await updateLicense(licenseId, licenseData);
        // Show success notification
        enqueueSnackbar('License updated successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error updating license:', error);
      }
    } else {
      try {
        await createLicense(licenseData);
        // Navigate to the license list page and show success notification
        navigate('/licenses');
        enqueueSnackbar('License created successfully', { variant: 'success' });
      } catch (error) {
        console.error('Error creating license:', error);
      }
    }
  };

  const handleGenerateKey = async () => {
    if (licenseId) {
      try {
        const newKey = await generateNewKeyFromService(licenseId);
        setLicenseData((prevData) => ({
          ...prevData,
          key: newKey,
        }));
      } catch (error) {
        console.error('Error generating new license key:', error);
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        {licenseId ? 'Edit License' : 'Create License'}
      </Typography>
      {/* TextFields for different license properties */}
      <TextField
        label="Description"
        name="description"
        value={licenseData.description !== undefined && licenseData.description !== null ? licenseData.description : ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      {licenseId && (
        <Box display="flex" alignItems="center" mb={2}>
          <TextField
            label="License Key"
            name="key"
            value={licenseData.key !== undefined && licenseData.key !== null ? licenseData.key : ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <Button variant="outlined" color="primary" onClick={handleGenerateKey} sx={{ ml: 2 }}>
            Generate
          </Button>
        </Box>
      )}
      <TextField
        label="Associated Account"
        name="associatedAccount"
        value={licenseData.associatedAccount !== undefined && licenseData.associatedAccount !== null ? licenseData.associatedAccount : ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Licensee Name"
        name="licenseToName"
        value={licenseData.licenseToName !== undefined && licenseData.licenseToName !== null ? licenseData.licenseToName : ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Licensee Email"
        name="licenseToEmail"
        value={licenseData.licenseToEmail !== undefined && licenseData.licenseToEmail !== null ? licenseData.licenseToEmail : ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="email"
        required
      />
      <FormControlLabel
        control={
          <Checkbox
          checked={licenseData.validateEquitySize}
            onChange={handleChange}
            name="validateEquitySize"
          />
        }
        label="Validate Equity Size"
      />
       <TextField
        label="Equity Size"
        name="equitySize"
        value={licenseData.equitySize !== undefined && licenseData.equitySize !== null ? licenseData.equitySize : ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="number"
        disabled={!licenseData.validateEquitySize}
      />
      <TextField
        label="Expiration Date"
        name="expiresOn"
        value={licenseData.expiresOn ? licenseData.expiresOn.split('T')[0] : ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={licenseData.active}
            onChange={handleChange}
            name="active"
          />
        }
        label="Active"
      />
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          {licenseId ? 'Update License' : 'Create License'}
        </Button>
      </Box>
    </Box>
  );
};

export default LicenseForm;