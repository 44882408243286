export const formatHours = (hours) => {
    const totalSeconds = Math.floor(hours * 3600);
    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = totalSeconds % 60;
    return `${h}h ${m}m ${s}s`;
  };
  
export const formatTime = (hours) => {
    const totalSeconds = Math.floor(hours * 3600);
    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = totalSeconds % 60;
    if (h === 0 && m === 0 && s === 0) {
      return 'None';
    }
    let result = '';
    if (h > 0) result += `${h}h `;
    if (m > 0) result += `${m}m `;
    result += `${s}s`;
    return result.trim();
  };
  
 export const formatDate = (isoString) => {
    // Parse the ISO string into a Date object
    const date = new Date(isoString);
  
    // Define an array of month names
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
    // Extract month, day, and year from the date
    const month = months[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0'); // Pad single-digit day with a leading zero
    const year = date.getFullYear();
  
    // Return the formatted date string
    return `${month}-${day}-${year}`;
  }
  