import * as React from 'react';
import { useEffect, useState } from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';
import { checkRiskAvailability } from '../services/riskService';
import { formatTime } from '../helpers/time_helper';

const RiskAvailabilityCard = () => {
  const [isRisky, setIsRisky] = useState(false);
  const [enableTrading, setEnableTrading] = useState(true);
  const [riskData, setRiskData] = useState({});
  const [loading, setLoading] = useState(true);
  const [lastEventTime, setLastEventTime] = useState(0);
  const [nextEventTime, setNextEventTime] = useState(0);

  useEffect(() => {
    const fetchRiskData = async () => {
      try {
        const response = await checkRiskAvailability({
          impacts: ["High"],
          currencies: [],
          hoursIntoNextEvent: 0,
          hoursFromLastEvent: 0,
          maxVixScore: 0,
        });
        setIsRisky(response.isRisky);
        setEnableTrading(response.enableTrading);
        setRiskData(response);
        setLastEventTime(response.lastEventInHours * 3600); // Convert hours to seconds
        setNextEventTime(response.nextEventInHours * 3600); // Convert hours to seconds
      } catch (error) {
        console.error('Error fetching risk data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRiskData();

    const vixInterval = setInterval(fetchRiskData, 10 * 60 * 1000); // Fetch every 10 mins
    return () => clearInterval(vixInterval);

  }, []);

  useEffect(() => {
    if (lastEventTime > 0 || nextEventTime > 0) {
      const countdownInterval = setInterval(() => {
        setLastEventTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        setNextEventTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [lastEventTime, nextEventTime]);

  return (
    <Box width={{ xs: '100%', md: '100%', lg: '100%' }} mb={3}>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isRisky ? 'red' : 'green',
          color: 'white',
          borderRadius: 2,
          boxShadow: 3
        }}
      >
        {loading ? (
          <Typography variant="h5">Loading...</Typography>
        ) : (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Risk 
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {isRisky ? 'High' : 'Low'}
                </Typography>
            </Box>
            <Divider sx={{ my: 2, borderColor: 'gray' }} />
            <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                        <strong>Number of Upcoming Events: </strong> {riskData.numberOfUpcomingEvents}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Next Event In Hours:</strong> {formatTime(nextEventTime / 3600)}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                        <strong>Number of Past Events: </strong> {riskData.numberOfPastEvents}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Last Event In Hours:</strong> {formatTime(lastEventTime / 3600)}
                    </Typography>
                </Box>
                {!enableTrading && (
                <>
                <Divider sx={{ my: 2, borderColor: 'gray' }} />
                <Box>
                  <Typography variant='body1'>
                    <strong>Trading disabled!</strong>
                  </Typography>
                </Box>
                </>
                )}              
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default RiskAvailabilityCard;
