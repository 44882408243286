import React from 'react';
import Layout from '../components/Layout';
import LicenseForm from '../components/LicenseForm';
import { useParams } from 'react-router-dom';

const LicenseFormPage = () => {
  const { id } = useParams(); // Get license ID from route params (if editing)
  return (
    <Layout>
      <LicenseForm licenseId={id} />
    </Layout>
  );
};

export default LicenseFormPage;