import * as React from 'react';
import { useEffect, useState } from 'react';
import { getEconomicCalendarByDate } from '../services/calendarService';
import { Box, Paper, Typography, Chip, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

const EconomicEventTimeline = ({ date }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchCalendar = async () => {
      try {
        const data = await getEconomicCalendarByDate(date);
        setEvents(data.filter(event => event.date === date));
      } catch (error) {
        console.error('Error fetching economic calendar events:', error);
      }
    };

    fetchCalendar();
  }, [date]);

  const formattedDate = (() => {
    const today = new Date().toISOString().split('T')[0];
    if (date === today) {
      return 'today';
    } else {
      const [year, month, day] = date.split('-');
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return `${day}-${monthNames[parseInt(month, 10) - 1]}-${year}`;
    }
  })();

  const getNextEventIndex = () => {
    const now = new Date();
    for (let i = 0; i < events.length; i++) {
      const eventTime = new Date(events[i].time);
      if (eventTime > now) {
        return i;
      }
    }
    return -1;
  };

  const nextEventIndex = getNextEventIndex();

  return (
    <Box>
      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Economic Events for {formattedDate}
        </Typography>
        {events.length === 0 ? (
          <Typography variant="body1">No events found for this date.</Typography>
        ) : (
          <List>
            {events.map((event, index) => (
              <ListItem
                key={index}
                sx={{
                  borderBottom: '1px solid #444',
                  backgroundColor: index === nextEventIndex ? 'rgba(0, 128, 0, 0.1)' : 'inherit',
                }}
              >
                <ListItemIcon>
                  <CheckCircleIcon color="success" />
                </ListItemIcon>
                <ListItemText
                  primary={`${(() => {
                    const time = new Date(event.time);
                    const isValidDate = !isNaN(time.getTime());
                    return isValidDate ? time.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : event.time;
                  })()} - ${event.currency} ${event.event}`}
                  secondary={`Impact: ${event.impact}`}
                  secondaryTypographyProps={{
                    sx: {
                      color: event.impact === 'High' ? 'red' : event.impact === 'Medium' ? 'orange' : 'green',
                      fontWeight: 'bold',
                    },
                  }}
                />
                <Chip
                  label={event.impact}
                  color={
                    event.impact === 'High'
                      ? 'error'
                      : event.impact === 'Medium'
                      ? 'warning'
                      : 'success'
                  }
                  size="small"
                  sx={{ ml: 1 }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Paper>
    </Box>
  );
};

export default EconomicEventTimeline;
