import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { getLicenses, deleteLicense } from '../services/licenseService';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const LicenseList = ({ onEdit }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [licenses, setLicenses] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('description');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const fetchLicenses = useCallback(async () => {
    try {
      const data = await getLicenses();
      setLicenses(data);
    } catch (error) {
      enqueueSnackbar('Error fetching licenses', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    fetchLicenses();
  }, [fetchLicenses]);

  const handleDelete = async (id) => {
    const userConfirmed = window.confirm('Are you sure you want to delete this license?');
    if (userConfirmed) {
      await deleteLicense(id);
      setLicenses(licenses.filter((license) => license.id !== id));
      enqueueSnackbar('License deleted successfully', { variant: 'success' });
    }
  };

  const handleCopy = (licenseKey) => {
    navigator.clipboard.writeText(licenseKey);
    enqueueSnackbar('License key copied to clipboard', { variant: 'success' });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = licenses.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const filteredLicenses = licenses.filter((license) =>
    license.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license.associatedAccount.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license.licenseToName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    license.licenseToEmail?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedLicenses = filteredLicenses.sort((a, b) => {
    if (orderBy) {
      let aValue = a[orderBy];
      let bValue = b[orderBy];

      if (orderBy === 'expiresOn') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else if (orderBy === 'equitySize') {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  return (
    <Paper elevation={3} sx={{ padding: '20px', margin: '20px' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center" sx={{ width: '70%' }}>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <InputBase
            placeholder="Search by description, licensee name, email, or account"
            inputProps={{ 'aria-label': 'search licenses' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginLeft: 1, flex: 1 }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate('/licenses/create')}
            sx={{ marginRight: '10px' }}
          >
            Add License
          </Button>
          <IconButton onClick={fetchLicenses}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < licenses.length}
                  checked={licenses.length > 0 && selected.length === licenses.length}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all licenses',
                  }}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'description'}
                  direction={orderBy === 'description' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'description')}
                >
                  Description
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'licenseToName'}
                  direction={orderBy === 'licenseToName' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'licenseToName')}
                >
                  Licensed To
                </TableSortLabel>
              </TableCell>
              <TableCell>Account #</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'equitySize'}
                  direction={orderBy === 'equitySize' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'equitySize')}
                >
                  Equity Size Cap
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'expiresOn'}
                  direction={orderBy === 'expiresOn' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'expiresOn')}
                >
                  Expires On
                </TableSortLabel>
              </TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLicenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((license) => {
              const isItemSelected = isSelected(license.id);
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, license.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={license.id}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': `enhanced-table-checkbox-${license.id}`,
                      }}
                    />
                  </TableCell>
                  <TableCell>{license.description}</TableCell>
                  <TableCell>{license.licenseToName}</TableCell>
                  <TableCell>{license.associatedAccount}</TableCell>
                  <TableCell>{(license.equitySize === 0 || !license.validateEquitySize) ? 'None' : license.equitySize}</TableCell>
                  <TableCell>{new Date(license.expiresOn).toLocaleDateString()}</TableCell>
                  <TableCell>
                    {license.active ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Copy">
                      <IconButton onClick={() => handleCopy(license.key)} sx={{ marginRight: '5px' }}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton color="primary" onClick={() => onEdit(license)} sx={{ marginRight: '5px' }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton color="secondary" onClick={() => handleDelete(license.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={licenses.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default LicenseList;
