import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Modal, Box, Button, TextField, Typography, Paper, Select, MenuItem, FormControlLabel, Checkbox, Chip, IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { predefinedImpacts, predefinedCurrencies } from '../helpers/constants';
import { formatDate } from '../helpers/time_helper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ScheduleCalendar = ({ riskSchedules, onSave, readOnly = false }) => {
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentSchedule, setCurrentSchedule] = useState({
    id: '',
    title: 'Risk',
    disableStartDateTimeUTC: '',
    disableEndDateTimeUTC: '',
    impacts: [],
    currencies: [],
    isEconomic: false,
    isAllDay: false,
    reoccuring: false,
  });

  useEffect(() => {
    const formattedEvents = riskSchedules.map(schedule => ({
      id: schedule.id,
      title: schedule.title,
      start: new Date(schedule.disableStartDateTimeUTC).toISOString(),
      end: new Date(schedule.disableEndDateTimeUTC).toISOString(),
      allDay: schedule.isAllDay,
      backgroundColor: schedule.reoccuring ? 'purple' : undefined,
      extendedProps: { ...schedule },
    }));
    setEvents(formattedEvents);
  }, [riskSchedules]);

  const handleDateClick = (info) => {
    if (readOnly) return;

    const localDate = new Date(info.dateStr);
    setSelectedDate(info.dateStr);
    setCurrentSchedule({
      id: uuidv4(),
      title: 'Risk',
      disableStartDateTimeUTC: localDate.toISOString().slice(0, 16),
      disableEndDateTimeUTC: localDate.toISOString().slice(0, 16),
      impacts: [],
      currencies: [],
      isEconomic: false,
      isAllDay: false,
      reoccuring: false,
    });
    setModalOpen(true);
  };

  const handleEventClick = (info) => {
    const schedule = riskSchedules.find(sch => sch.id === info.event.id);
    if (schedule) {
      setCurrentSchedule({
        ...schedule,
        disableStartDateTimeUTC: new Date(schedule.disableStartDateTimeUTC).toLocaleString('sv-SE').replace(' ', 'T'),
        disableEndDateTimeUTC: new Date(schedule.disableEndDateTimeUTC).toLocaleString('sv-SE').replace(' ', 'T'),
      });
    }
    setSelectedDate(info.event.startStr);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (field, value) => {
    setCurrentSchedule(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    if (readOnly) return;

    const localStart = new Date(currentSchedule.disableStartDateTimeUTC);
    const localEnd = new Date(currentSchedule.disableEndDateTimeUTC);

    const updatedSchedule = {
      ...currentSchedule,
      disableStartDateTimeUTC: localStart.toISOString(),
      disableEndDateTimeUTC: localEnd.toISOString(),
    };

    const updatedSchedules = riskSchedules.some(sch => sch.id === currentSchedule.id)
      ? riskSchedules.map(sch => sch.id === currentSchedule.id ? updatedSchedule : sch)
      : [...riskSchedules, updatedSchedule];

    setEvents(updatedSchedules.map(schedule => ({
      id: schedule.id,
      title: schedule.title,
      start: new Date(schedule.disableStartDateTimeUTC).toISOString(),
      end: new Date(schedule.disableEndDateTimeUTC).toISOString(),
      allDay: schedule.isAllDay,
      backgroundColor: schedule.reoccuring ? 'purple' : undefined,
      extendedProps: { ...schedule },
    })));

    onSave(updatedSchedules);
    setModalOpen(false);
  };

  const handleDelete = () => {
    if (readOnly) return;

    const updatedSchedules = riskSchedules.filter(sch => sch.id !== currentSchedule.id);

    setEvents(updatedSchedules.map(schedule => ({
      id: schedule.id,
      title: schedule.title,
      start: new Date(schedule.disableStartDateTimeUTC).toISOString(),
      end: new Date(schedule.disableEndDateTimeUTC).toISOString(),
      allDay: schedule.isAllDay,
      backgroundColor: schedule.reoccuring ? 'purple' : undefined,
      extendedProps: { ...schedule },
    })));

    onSave(updatedSchedules);
    setModalOpen(false);
  };

  const handleCopy = () => {
    if (readOnly) return;

    const localStart = new Date(currentSchedule.disableStartDateTimeUTC);
    const localEnd = new Date(currentSchedule.disableEndDateTimeUTC);

    const copiedSchedule = {
      ...currentSchedule,
      id: uuidv4(), // Assign a new unique ID to the copied event
      disableStartDateTimeUTC: localStart.toISOString(),
      disableEndDateTimeUTC: localEnd.toISOString(),
    };

    const updatedSchedules = [...riskSchedules, copiedSchedule];

    setEvents(updatedSchedules.map(schedule => ({
      id: schedule.id,
      title: schedule.title,
      start: new Date(schedule.disableStartDateTimeUTC).toISOString(),
      end: new Date(schedule.disableEndDateTimeUTC).toISOString(),
      allDay: schedule.isAllDay,
      backgroundColor: schedule.reoccuring ? 'purple' : undefined,
      extendedProps: { ...schedule },
    })));

    onSave(updatedSchedules);
  };

  return (
    <Box>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        selectable={!readOnly}
        editable={!readOnly}
        height="auto"
      />
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Paper sx={{ p: 3, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" gutterBottom>
              {readOnly ? 'View Schedule' : currentSchedule.id ? 'Edit Schedule' : 'Add Schedule'} on {formatDate(selectedDate)}
            </Typography>
            {!readOnly && currentSchedule.id && (
              <IconButton onClick={handleCopy} color="secondary">
                <ContentCopyIcon />
              </IconButton>
            )}
          </Box>
          <TextField
            label="Title"
            value={currentSchedule.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: readOnly,
            }}
          />
          <TextField
            label="Disable Start DateTime"
            type="datetime-local"
            value={currentSchedule.disableStartDateTimeUTC}
            onChange={(e) => handleInputChange('disableStartDateTimeUTC', e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: readOnly,
            }}
          />
          <TextField
            label="Disable End DateTime"
            type="datetime-local"
            value={currentSchedule.disableEndDateTimeUTC}
            onChange={(e) => handleInputChange('disableEndDateTimeUTC', e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: readOnly,
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentSchedule.reoccuring}
                onChange={(e) => handleInputChange('reoccuring', e.target.checked)}
                color="primary"
                disabled={readOnly}
              />
            }
            label="Re-Occurring"
          />
          <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>Impacts:</Typography>
          <Select
            multiple
            value={currentSchedule.impacts}
            onChange={(e) => handleInputChange('impacts', e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} color="warning" size="small" />
                ))}
              </Box>
            )}
            fullWidth
            sx={{ mt: 1 }}
            disabled={readOnly}
          >
            {predefinedImpacts.map((impact) => (
              <MenuItem key={impact} value={impact}>{impact}</MenuItem>
            ))}
          </Select>
          <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>Currencies:</Typography>
          <Select
            multiple
            value={currentSchedule.currencies}
            onChange={(e) => handleInputChange('currencies', e.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} color="primary" size="small" />
                ))}
              </Box>
            )}
            fullWidth
            sx={{ mt: 1 }}
            disabled={readOnly}
          >
            {predefinedCurrencies.map((currency) => (
              <MenuItem key={currency} value={currency}>{currency}</MenuItem>
            ))}
          </Select>
          <FormControlLabel
            control={
              <Checkbox
                checked={currentSchedule.isEconomic}
                onChange={(e) => handleInputChange('isEconomic', e.target.checked)}
                color="primary"
                disabled={readOnly}
              />
            }
            label="Is Economic"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={currentSchedule.isAllDay}
                onChange={(e) => handleInputChange('isAllDay', e.target.checked)}
                color="primary"
                disabled={readOnly}
              />
            }
            label="Is All Day"
          />
          {!readOnly && (
            <Box display="flex" justifyContent="space-between" mt={3}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              {currentSchedule.id && (
                <Button variant="outlined" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              )}
            </Box>
          )}
          {readOnly && (
            <Box display="flex" justifyContent="flex-end" mt={3}>
              <Button variant="contained" color="primary" onClick={handleModalClose}>
                Close
              </Button>
            </Box>
          )}
        </Paper>
      </Modal>
    </Box>
  );
};

export default ScheduleCalendar;
