// RiskSettingsPage.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../components/Layout';
import RiskSettingsEdit from '../components/RiskSettingsEdit';
import RiskSettings from '../components/RiskSettings';
import { Grid } from '@mui/material';

const RiskSettingsPage = () => {
  const location = useLocation();
  const isEdit = location.pathname === '/risksettings/edit';

  return (
    <Layout>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12}>
          {isEdit ? <RiskSettingsEdit /> : <RiskSettings />}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default RiskSettingsPage;