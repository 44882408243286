import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getEconomicCalendar } from '../services/calendarService';
import { Box, Paper, Typography, Chip } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'; // New plugin for interactivity

const EconomicCalendar = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCalendar = async () => {
      try {
        const data = await getEconomicCalendar();
        
        // Aggregate event counts by date and impact
        const eventCountsByDate = data.reduce((acc, event) => {
          const date = event.date;
          if (!acc[date]) {
            acc[date] = { High: 0, Medium: 0, Low: 0 };
          }
          acc[date][event.impact] = (acc[date][event.impact] || 0) + 1;
          return acc;
        }, {});

        // Format the events for FullCalendar
        const formattedEvents = Object.entries(eventCountsByDate).map(([date, impacts]) => ({
          title: `${impacts.High} High, ${impacts.Medium} Medium, ${impacts.Low} Low`,
          extendedProps: { high: impacts.High, medium: impacts.Medium, low: impacts.Low },
          start: date,
          allDay: true,
        }));
        setEvents(formattedEvents);

        // Set document title with total impact counts
        const totalImpactCounts = data.reduce((acc, event) => {
          acc[event.impact] = (acc[event.impact] || 0) + 1;
          return acc;
        }, {});
        document.title = `Economic Calendar - High: ${totalImpactCounts.High || 0}, Medium: ${totalImpactCounts.Medium || 0}, Low: ${totalImpactCounts.Low || 0}`;
      } catch (error) {
        console.error('Error fetching calendar events:', error);
      }
    };
  
    fetchCalendar();
  }, []);  

  const handleDateClick = (info) => {
    console.log(info);
    navigate(`/calendar/detail/${info.startStr}`);
  };

  return (
    <Box>
      <Paper sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Economic Calendar
        </Typography>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          // validRange={{ start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) }}
          events={events}
          eventContent={(arg) => (
            <Box onClick={() => handleDateClick({ dateStr: arg.event.startStr })} display="flex" flexDirection="column" sx={{ backgroundColor: 'white' }}>
              {arg.event.extendedProps.high > 0 && (
                <Chip label={`High: ${arg.event.extendedProps.high}`} color="error" size="small" sx={{ marginBottom: 0.5 }} />
              )}
              {arg.event.extendedProps.medium > 0 && (
                <Chip label={`Medium: ${arg.event.extendedProps.medium}`} color="warning" size="small" sx={{ marginBottom: 0.5 }} />
              )}
              {arg.event.extendedProps.low > 0 && (
                <Chip label={`Low: ${arg.event.extendedProps.low}`} color="success" size="small" sx={{ marginBottom: 0.5 }} />
              )}
            </Box>
          )}
          height="auto"
          selectable={true}
          select={(info) => handleDateClick(info)}
        />
      </Paper>
    </Box>
  );
};

export default EconomicCalendar;
