import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import EconomicEventTimeline from '../components/EconomicEventTimeline';
import { Grid } from '@mui/material';

const EconomicCalendarDetailPage = () => {
  const { date } = useParams();

  return (
    <Layout>
      <Grid container spacing={3} mb={12}>
        <Grid item xs={12} md={12}>
          <EconomicEventTimeline date={date} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default EconomicCalendarDetailPage;
