import React from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';

const VixCard = ({ vixData }) => (
  <Box width={{ xs: '100%', md: '100%', lg: '100%' }}>
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#1E1E1E',
        color: 'white',
        borderRadius: 2,
        boxShadow: 3
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          ^VIX
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
          {vixData.vix_score !== 0 ? vixData.vix_score : 'Loading...'}
        </Typography>
        <Typography variant="h5" color="error" sx={{ fontWeight: 'bold' }}>
          {vixData.prev_close !== 0 ? `${vixData.percentage_change.toFixed(2)}%` : ''}
        </Typography>
      </Box>
      <Divider sx={{ my: 2, borderColor: 'gray' }} />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="body1">
          <strong>Prev. Close:</strong> {vixData.vix_score !== 0 ? vixData.prev_close : 'Loading...'}
        </Typography>
        <Typography variant="body1">
          <strong>Open:</strong> {vixData.vix_score !== 0 ? vixData.open_price : 'Loading...'}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1">
            <strong>52 Week High:</strong> {vixData.vix_score !== 0 ? vixData["52_week_high"] : 'Loading...'}
          </Typography>
          <Typography variant="body1">
            <strong>Low:</strong> {vixData.vix_score !== 0 ? vixData["52_week_low"] : 'Loading...'}
          </Typography>
        </Box>
      </Box>
    </Paper>
  </Box>
);

export default VixCard;
