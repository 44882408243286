import * as React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Event as EventIcon,
  LibraryBooks as LibraryBooksIcon,
  Add as AddIcon,
  Security as SecurityIcon,
} from '@mui/icons-material';

export const mainListItems = (navigate) => (
  <div>
    {/* Dashboard */}
    <ListItem button={true} onClick={() => navigate('/')}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>

    {/* Economic Calendar */}
    <ListItem button={true} onClick={() => navigate('/calendar')}>
      <ListItemIcon>
        <EventIcon />
      </ListItemIcon>
      <ListItemText primary="Economic Calendar" />
    </ListItem>

    {/* Licenses */}
    <ListItem button={true} onClick={() => navigate('/licenses')}>
      <ListItemIcon>
        <LibraryBooksIcon />
      </ListItemIcon>
      <ListItemText primary="Licenses" />
    </ListItem>

    {/* Create License */}
    <ListItem button={true} onClick={() => navigate('/licenses/create')}>
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText primary="Create License" />
    </ListItem>

    {/* Risk Settings */}
    <ListItem button={true} onClick={() => navigate('/risksettings')}>
      <ListItemIcon>
        <SecurityIcon />
      </ListItemIcon>
      <ListItemText primary="Risk Settings" />
    </ListItem>
  </div>
);
