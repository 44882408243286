// riskSettingsService.js
import axiosInstance from './authService';

export const getRiskSettings = async () => {
  try {
    const response = await axiosInstance.get('/GetRiskSettings');
    return response.data;
  } catch (error) {
    console.error('Error fetching risk settings:', error);
    throw error;
  }
};

export const updateRiskSettings = async (updatedSettings) => {
  try {
    const response = await axiosInstance.post('/UpdateRiskSettings', updatedSettings);
    return response.data;
  } catch (error) {
    console.error('Error updating risk settings:', error);
    throw error;
  }
};