import * as React from 'react';
import Layout from '../components/Layout';
import EconomicCalendar from '../components/EconomicCalendar';
import { Box } from '@mui/material';

const EconomicCalendarPage = () => {
  return (
    <Layout>
      <Box sx={{ p: 3 }}>
        <EconomicCalendar />
      </Box>
    </Layout>
  );
};

export default EconomicCalendarPage;
