import React from 'react';
import Layout from '../components/Layout';
import LicenseList from '../components/LicenseList';
import { useNavigate } from 'react-router-dom';

const LicenseListPage = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <LicenseList onEdit={(license) => navigate(`/licenses/edit/${license.id}`)} />
    </Layout>
  );
};

export default LicenseListPage;